import { Layout } from 'antd';

import { Page } from '@/containers';

const { Content } = Layout;

const Custom404Page = () => {
  return (
    <Layout>
      <Content>
        <Page title="404">Страница не найдена</Page>
      </Content>
    </Layout>
  );
};

export default Custom404Page;
